/**
 * Suppress RequireJS errors during unload
 */
(function() {

  function doUnload() {
    window.exitting = true;
  }

  if (window.addEventListener) {
    window.addEventListener('beforeunload', doUnload, false);
  }
})();


// ===================================================================== //


/**
 * Global JavaScript error handling function.
 * Will log the error into Google Analytics if we're not debugging, otherwise it outputs the error data
 * that would normally be logged into GA in console.
 *
 * This error handler will display file path and line for syntax errors but will only display error message
 * from requireJS for requireJS-specific errors (such as file not found or no define function found in a module).
 */
var ErrorHandling = (function() {

  // do not log false errors when navigating away
  if (window.exitting) return true;

  var logToConsole = function(message, url, lineNumber) {

    // if message is an object, it came from requireJS and should be treated as such
    if (typeof (message) === 'object') {

      if (message.message) {
        message = message.message.split('\n')[0];
      } else if (message.target) {

        // an event object has been passed to the error function - build up a log message from it
        var tmp = [];
        for (var i in message) {
          if (message.hasOwnProperty(i)) {
            tmp[tmp.length] = i + ' = ' + message[i];
          }
        }

        // try to determine from which element this originates
        if (message.target.id) {
          tmp[tmp.length] = 'element ID = ' + message.target.id;
        }

        if (message.target.name) {
          tmp[tmp.length] = 'element NAME = ' + message.target.name;
        }

        if (message.target.src) {
          tmp[tmp.length] = 'element SRC = ' + message.target.src;
        }

        message = tmp.join(', ');
      }
    }

    // prepare data
    var grouping = 'JavaScript Error';
    var fullMessage = 'Message: ' + message + (url ? ' / Source: ' + url : '') + (lineNumber ? ' / Line: ' + lineNumber : '');
    var eventCollection = [grouping, fullMessage];

    // running in development
    if (typeof console !== 'undefined') {
      window.console.log(eventCollection);
    }
  };

  return {
    logError: function(message, url, lineNumber, columnNo, error) {
      // do not log false errors when navigating away
      if (window.exitting) return true;
      logToConsole(message, url, lineNumber);
      return !window.siteObj.debugFlag;
    }
  };
}());

window.onerror = ErrorHandling.logError;


// ===================================================================== //


/**
 * Global error handler for requireJS-related errors.
 * Builds information about the error and passes it to the global error handling function,
 * so it can be logged and possibly displayed to developers (if debugging is enabled).
 */
window.requirejs.onError = function(err) {

  // do not log false errors when navigating away
  if (window.exitting) return;

  var modName = (err.requireModules && err.requireModules.length) ? err.requireModules[0] : 'unknown';
  var paths = {};
  var shim = {};
  var conf = {};

  paths[modName] = window.requirejs.s.contexts._.config.paths[modName];
  shim[modName] = (window.requirejs.s.contexts._.config.shim ? window.requirejs.s.contexts._.config.shim[modName] : '');
  conf.paths = paths;

  if (shim[modName]) {
    conf.shim = shim;
  }

  // if we've got a timeout, try to load the same script again (up to 2 additional times)
  /** @namespace scriptTimeouts */
  if (err.requireType === 'timeout' && (!window.scriptTimeouts[modName] || window.scriptTimeouts[modName] <= 3)) {
    window.scriptTimeouts[modName] = window.scriptTimeouts[modName] + 1 || 1;
    window.requirejs.undef(modName);

    window.requirejs.config(conf);
    require([modName], function() {});
  }

  if (window.siteObj && window.siteObj.debugFlag) {
    throw err;
  } else {
    ErrorHandling.logError(err);
  }
};


//===================================================================== //


/**
 * Logs module load times via performance.timing.mark
 */
window.requirejs.onResourceLoad = function(context, map /*, depArray*/ ) {

  //var markName;

  // Check for timing support, mark load time
  /** @namespace performance */
  if (map && map.id && window.performance && window.performance.mark) {
    window.performance.mark(map.id);
  }
};


//===================================================================== //


/**
 * Application requires access to internal RequireJS paths config
 */
if (!window.requirejs.s || !window.requirejs.s.contexts || !window.requirejs.s.contexts._ || !window.requirejs.s.contexts._.config || !window.requirejs.s.contexts._.config.paths) {
  window.console.error('requireJS interface "requirejs.s.contexts._.config.paths" is no longer available. This interface is used to determine which modules to load per site configuration.\n\nPlease check for updates on this matter and fix code of ALL site JS files accordingly!');
}


//===================================================================== //

/**
 * Optionally load modules
 */
define('optional', [], {
  load: function(moduleName, parentRequire, onload) {

    var onLoadSuccess = function(moduleInstance) {
      // Module successfully loaded, call the onload callback so that
      // requirejs can work its internal magic.
      onload(moduleInstance);
    };

    var onLoadFailure = function(err) {
      // optional module failed to load.
      var failedId = err.requireModules && err.requireModules[0];
      window.console.warn('Could not load OPTIONAL module: ' + failedId);

      // Undefine the module to cleanup internal stuff in requireJS
      window.requirejs.undef(failedId);

      // Now define the module instance as a simple empty object
      // (NOTE: you can return any other value you want here)
      define(failedId, [], function() {
        return {};
      });

      // Now require the module make sure that requireJS thinks
      // that is it loaded. Since we've just defined it, requirejs
      // will not attempt to download any more script files and
      // will just call the onLoadSuccess handler immediately
      parentRequire([failedId], onLoadSuccess);
    };

    parentRequire([moduleName], onLoadSuccess, onLoadFailure);
  }
});

/**
 * Set up RequireJS
 */
(function(namespace) {

  var config = namespace.config;
  var protocol = 'https://';
  var url = protocol + '#{$js-cdn-token}';
  var environment = (!window.siteObj || !window.siteObj.debugFlag) ? 'PROD' : 'DEV';
  var useSiteObjCdn = environment !== 'DEV' && window.siteObj.features
    && (window.siteObj.features.hasChinaCdn || window.siteObj.features.hasStaticCdn);

  var requireJSConfig = {
    baseUrl: useSiteObjCdn ? window.siteObj.siteCDNPath + '/www' : url,
    waitSeconds: 15,
    enforceDefine: true,
    paths: config.paths,
    shim: config.shim
  };

  /**
   * Path to site module
   */
  config.paths.site = window.siteObj.siteCode;

  define('manifest', ['optional!revManifest', 'requireHelper'], function(manifest, RequireHelper) {
    var helper = new RequireHelper(window.siteObj);

    /**
     * Check manifest for data
     * @param object
     * @returns {boolean}
     */
    function hasData(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          return true;
        }
      }
      return false;
    }

    if (hasData(manifest)) {
      for (var script in config.paths) {
        config.paths[script] = helper.getUpdatedPath(config.paths[script], manifest);
      }
    }
    /**
     * Load site JS dependencies and lazy-load components
     */
    require(['componentHelper'], function(helper)
    {
      helper.requireDependenciesAndComponents();
    });
    /**
     * Initial config
     */
    window.requirejs.config(requireJSConfig);
  });
  require(['manifest']);
})(window.THEHUT);
